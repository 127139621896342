import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	connect() {
		this.element.addEventListener("change", this.showDateRangeField.bind(this));
	}

	showDateRangeField() {
		let dateRangeField = document.getElementById("date-range-field");

		if (this.element.value === "custom") {
			dateRangeField.classList.remove("hidden");
		} else {
			dateRangeField.classList.add("hidden");
		}
	}
}
