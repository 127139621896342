import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gtag"
export default class extends Controller {
  sendEvent({ params: { event, payload } }) {
    event.split(",").forEach(event_name => {
      if (event_name === "") return
      if (this.isDevEnvironment) {
        console.log("gtag", "event", event_name, payload)
      } else {
        gtag("event", event_name, payload)
      }
    })
  }

  get isDevEnvironment() {
    return document.head.querySelector("meta[name=rails_env]").content === "development"
  }
}
