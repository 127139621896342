import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company-form--address-fields"
export default class extends Controller {
  static targets = ["countryField", "stateField"]
  static values = { usTerritoryIds: Array }

  connect() {
    this.initializeFields()
  }

  initializeFields() {
    if (this.usTerritoryIdsValue.includes(Number(this.countryFieldTarget.value))) {
      this.hideStateField()
    } else {
      this.stateFieldTarget.required = true
    }
  }

  selectCountry(e) {
    if (this.usTerritoryIdsValue.includes(Number(e.target.value))) {
      this.hideStateField()
    } else {
      this.showStateField()
    }
  }

  hideStateField() {
    this.stateFieldTarget.parentElement.classList.add("d-none")
    this.stateFieldTarget.value = ""
    this.stateFieldTarget.required = false
  }

  showStateField() {
    this.stateFieldTarget.parentElement.classList.remove("d-none")
    this.stateFieldTarget.value = ""
    this.stateFieldTarget.required = true
  }
}
