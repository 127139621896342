import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'allPagesSelectionRow',
    'bulkSelect',
    'checkbox',
    'currentPageSelectionRow',
    'file',
    'row',
    'selectedIds',
  ]

  toggleAll(event) {
    const checkboxes = this.checkboxTargets.filter((n) => !n.disabled)
    const allChecked =
      event.params.check ??
      (checkboxes.length === 0
        ? this.bulkSelectTarget.checked
        : !checkboxes.some((n) => n.checked))
    this.bulkSelectTarget.indeterminate = false
    this.bulkSelectTarget.checked = allChecked
    this.currentPageSelectionRowTarget.classList.toggle('d-none', !allChecked)
    checkboxes.forEach((checkbox) => {
      checkbox.checked = allChecked
      this.rowTargets.forEach((row) => {
        if (!row.contains(checkbox)) return

        row.classList.toggle('table-primary', allChecked)
      })
    })
    this.fileTarget.disabled = !allChecked
    this.storeSelection()
  }

  selectAll() {
    this.currentPageSelectionRowTarget.classList.add('d-none')
    this.allPagesSelectionRowTarget.classList.remove('d-none')
    this.selectedIdsTarget.value = 'all_fileable'
  }

  clearSelection() {
    const checkboxes = this.checkboxTargets.filter((n) => !n.disabled)
    this.bulkSelectTarget.indeterminate = false
    this.bulkSelectTarget.checked = false
    this.allPagesSelectionRowTarget.classList.add('d-none')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.fileTarget.disabled = true
    this.storeSelection()
  }

  toggle(event) {
    const checkboxes = this.checkboxTargets.filter((n) => !n.disabled)
    const checked = checkboxes.some((n) => n.checked)
    const unchecked = checkboxes.some((n) => !n.checked)
    const allChecked = checked && !unchecked
    this.bulkSelectTarget.checked = allChecked
    this.bulkSelectTarget.indeterminate = checked && unchecked
    this.currentPageSelectionRowTarget.classList.toggle('d-none', !allChecked)
    this.allPagesSelectionRowTarget.classList.add('d-none')
    this.rowTargets.forEach((row) => {
      if (!row.contains(event.currentTarget)) return

      row.classList.toggle('table-primary', event.currentTarget.checked)
    })
    this.fileTarget.disabled = !checked
    this.storeSelection()
  }

  storeSelection() {
    const ids = this.checkboxTargets
      .filter((n) => n.checked && !n.disabled)
      .map((n) => n.value)
    this.selectedIdsTarget.value = ids.join(',')
  }
}
