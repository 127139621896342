import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="address-form"
export default class extends Controller {
  static targets = ["countryField", "stateField"]

  connect() {
    this.initializeFields()
  }

  initializeFields() {
    switch (this.selectedCountryType) {
      case "Territory":
      case "Foreign":
        this.stateFieldTarget.disabled = true
        break
      case "US":
      default:
        this.stateFieldTarget.disabled = false
        break
    }
  }

  selectCountry() {
    switch (this.selectedCountryType) {
      case "Territory":
      case "Foreign":
        this.enableStateField(false)
        break
      case "US":
      default:
        this.enableStateField(true)
        break
    }
  }

  enableStateField(enable) {
    this.stateFieldTarget.disabled = !enable
    this.stateFieldTarget.value = ""
  }

  get selectedCountryType() {
    return this.countryFieldTarget.selectedOptions[0].dataset.countryType
  }
}
