import { Controller } from "@hotwired/stimulus";
import { csrfToken } from "@rails/ujs";

const EMAIL_REGEX = /^[\w!#$%&'*+\/=?`{|}~^.-]+(?:\.[\w!#$%&'*+\/=?`{|}~^.-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,18}$/;

export default class extends Controller {
	static targets = ["userErrorWarning", "otherErrorWarning", "submitButton"];

	initialize() {
		this.submitButtonTarget.disabled = true;
		this.checkFormValidity();
	}

	checkFormValidity() {
		const form = this.element;
		const requiredFields = form.querySelectorAll("[required]");

		requiredFields.forEach((field) => {
			field.addEventListener("input", () => {
				const allFilled = Array.from(requiredFields).every((input) => input.value.trim() !== "");
				this.submitButtonTarget.disabled = !allFilled;
			});
		});
	}

	areRequiredFieldsFilled(form) {
		const requiredFields = form.querySelectorAll("[required]");
		return Array.from(requiredFields).every((field) => field.value.trim() !== "");
	}

	isEmailValid(email) {
		return EMAIL_REGEX.test(email);
	}

	submit(event) {
		event.preventDefault();
		const form = this.element;

		if (!this.areRequiredFieldsFilled(form)) {
			this.otherErrorWarningTarget.textContent = "Please fill out all required fields.";
			this.otherErrorWarningTarget.classList.remove("hidden");
			return;
		}

		const formData = new FormData(form);
		const email = formData.get("user[email]");

		if (!this.isEmailValid(email)) {
			this.otherErrorWarningTarget.textContent = "Please enter a valid email address.";
			this.otherErrorWarningTarget.classList.remove("hidden");
			return;
		}

		this.otherErrorWarningTarget.classList.add("hidden");
		this.userErrorWarningTarget.classList.add("hidden");

		const recaptchaResponse = form.querySelector('[name="g-recaptcha-response"]')?.value;

		const productId = formData.get("product_id");
		const companyName = formData.get("company_name");
		const mode = formData.get("mode");

		fetch(form.action, {
			method: "POST",
			headers: {
				"X-CSRF-Token": csrfToken(),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user: {
					email: email,
					password: formData.get("user[password]"),
					password_confirmation: formData.get("user[password_confirmation]"),
					user_property_attributes: {
						legal_first_name: formData.get("user[user_property_attributes][legal_first_name]"),
						legal_last_name: formData.get("user[user_property_attributes][legal_last_name]"),
					},
					recaptcha_token: recaptchaResponse,
				},
				company_name: companyName,
				product_id: productId,
				mode: mode,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.sessionId) {
					stripe.redirectToCheckout({ sessionId: data.sessionId });
				} else if (data.error) {
					if (data.error?.existingUser) {
						this.userErrorWarningTarget.classList.remove("hidden");
					} else {
						grecaptcha.reset();
						const error_message = data.error["recaptcha"] ? data.error["recaptcha"] : data.error;
						this.otherErrorWarningTarget.textContent = error_message;
						this.otherErrorWarningTarget.classList.remove("hidden");
					}
				}
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	}
}
